// Brand Font
@font-face {
  font-family: 'calibre-light';
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Light.eot');
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Light.eot?#iefix') format('embedded-opentype'),
  url('../../src/styles/fonts/calibre/CalibreWeb-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'calibre-medium';
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Medium.eot');
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../src/styles/fonts/calibre/CalibreWeb-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'calibre-regular';
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Regular.eot');
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../src/styles/fonts/calibre/CalibreWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'calibre-semibold';
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Semibold.eot');
  src: url('../../src/styles/fonts/calibre/CalibreWeb-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../../src/styles/fonts/calibre/CalibreWeb-Semibold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}