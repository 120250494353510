@import "../../../src/styles/legacy/typography";

.markdownHeadingLink {
  @extend .fontColorSecondary;
  margin-left: -1em;
  opacity: 0;
  transition: all 0.2s;
  font-size: 24px;

  h1:hover &,
  h2:hover &,
  h3:hover &,
  h4:hover &,
  h5:hover &,
  h6:hover & {
    opacity: 1;
  }
}
