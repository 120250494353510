@import "fonts";
@import "../../src/styles/legacy/breakpoints";
@import "../../src/styles/legacy/typography";

$sidebarWidth: 320px;
$sidebarWidth_Small: 200px;
$sidebarWidth_XSmall: 160px;

.container {
  display: flex;
  flex-flow: row;
}

.sideBar {
  display: flex;
  flex-flow: row-reverse;
  position: fixed;
  background-color: #F2F5F7;
  width: $sidebarWidth;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;

  & .inner-container {
    width: 280px;
    margin: 16px auto;

    @include breakpoint(smallSize) {
      width: 240px;
    }
    @include breakpoint(xSmallSize) {
      width: 200px;
    }

    & .logo-header {
      display: flex;
      padding: 16px;
      margin-bottom: 48px;

      & .brandName {
        min-width: 160px;
      }
    }
  }

  @include breakpoint(smallSize) {
    width: $sidebarWidth_Small;
    padding: 16px 8px 16px 8px;
  }
  @include breakpoint(xSmallSize) {
    width: $sidebarWidth_XSmall;
    padding: 16px 8px 16px 8px;
  }
}

.docs-content-container {
  min-width: 240px;
  max-width: 800px;
  margin-left: $sidebarWidth;
  padding: 4px 48px;

  @include breakpoint(smallSize) {
    margin-left: $sidebarWidth_Small;
  }
  @include breakpoint(xSmallSize) {
    margin-left: $sidebarWidth_XSmall;
  }

  h1 {
    @extend .display3;
    line-height: 1.2;
    margin: 24px 0 24px 0 !important;
  }

  h2 {
    @extend .display2;
    line-height: 1.3;
    margin: 48px 0 16px 0 !important;
  }

  h3 {
    @extend .display1;
    line-height: 1.2;
    margin: 48px 0 16px 0 !important;
  }

  h4 {
    @extend .title;
    line-height: 1.2;
    margin: 32px 0 16px 0 !important;
  }

  h5 {
    @extend .subheading;
    line-height: 1.2;
    margin: 32px 0 16px 0 !important;
  }

  h6 {
    @extend .subheading;
    line-height: 1.2;
    margin: 24px 0 16px 0 !important;
  }

  p {
    img {
      max-width: 100%;
      width: auto;
    }

    line-height: 1.5;
  }

}

body {
  font-family: 'calibre-regular', 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1 !important;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

h1 {
  @extend .display3;
  line-height: 1.4;
}

h2 {
  @extend .display2;
  line-height: 1.3;
}

h3 {
  @extend .display1;
  line-height: 1.2;
}

h4 {
  @extend .headline;
  line-height: 1.2;
}

h5 {
  @extend .title;
  line-height: 1.2;
}

h6 {
  @extend .subheading;
  line-height: 1.2;
}

.intro {
  font-size: 160%;
  line-height: 1.2 !important;
  color: rgba($black, .54);
}

p, li {
  > code {
    background-color: rgb(240, 240, 240);
    font-size: 13px !important;
    padding: 0 3px;
  }

  line-height: 1.57;
}

code {
  font-size: 14px !important;
  line-height: 24px !important;
  //padding: 16px;
}

div > pre > code {
  line-height: 24px !important;
}

table {
  border-left: 1px solid $greyLight;
  padding: 0 8px;

  &.no-border {
    border: none;
  }

  & tr:nth-child(even) {
    background: $greyLight;
  }

  & td {
    padding: 16px 8px;
    margin: 0;
  }

}

.codeTabsContainer {
  border: 1px solid $greyLight;
  padding: 8px;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 1px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 1px #2056F7;
  //border-color: hsl(208, 99%, 50%);
  border-color: #2056F7;
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}
